:root {
  --font-sans: 'Source Sans Pro';
  --font-mono: 'Source Code Pro';

  --margin-01: 8px;
  --margin-02: calc(var(--margin-01) * 2);
  --margin-03: calc(var(--margin-01) * 3);
  --margin-04: calc(var(--margin-01) * 4);
  --margin-05: calc(var(--margin-01) * 5);
  --margin-half: calc(var(--margin-01) / 2);
  --max-width: 512px;

  --bg: #ffffff;
  --bg-a: rgba(51, 45, 45, 0.68);
  --fg: #332d2d;
  --hl: #eca3a3;
  --hl-alt: #f9dddd;
}
