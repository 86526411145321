@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro:400,700|Source+Sans+Pro);
:root {
  --font-sans: 'Source Sans Pro';
  --font-mono: 'Source Code Pro';

  --margin-01: 8px;
  --margin-02: calc(var(--margin-01) * 2);
  --margin-03: calc(var(--margin-01) * 3);
  --margin-04: calc(var(--margin-01) * 4);
  --margin-05: calc(var(--margin-01) * 5);
  --margin-half: calc(var(--margin-01) / 2);
  --max-width: 512px;

  --bg: #ffffff;
  --bg-a: rgba(51, 45, 45, 0.68);
  --fg: #332d2d;
  --hl: #eca3a3;
  --hl-alt: #f9dddd;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 100%;
  background: var(--bg);
  color: var(--fg);
  font-family: var(--font-sans), sans-serif;
}

body {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAEAQMAAACTPww9AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAAA1JREFUCNdjOACEQAAACQgBgeEHEZ0AAAAASUVORK5CYII=);
  background-size: auto;
  background-size: 2px 2px;
}

h1,
h2,
h3,
h4 {
  margin: 0px;
  text-transform: uppercase;
}

h1 {
  font-size: 1.5em;
  text-align: center;
}

h2 {
  font-size: 1.3em;
  font-weight: normal;
}

h3 {
  font-size: 1em;
  font-weight: bolder;
}

.-mono {
  font-family: var(--font-mono), mono;
}

.wrapper {
  max-width: var(--max-width);
  width: calc(100% - var(--margin-04));
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.wrapper.-box {
  position: relative;
  background: var(--bg);
  border: 2px solid var(--fg);
  box-shadow: inset 1px 1px 0 0 #fff, 5px 5px 0 rgba(0, 0, 0, 0.2);
}

.app {
  padding-top: var(--margin-04);
  padding-bottom: var(--margin-04);
  flex: 1 0 auto;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  @media not all and (hover: hover) {
    height: 100%;
    min-height: 100%;
  }
}

section {
  padding: var(--margin-02);
}

section:not(:last-child) {
  border-bottom: 2px solid var(--fg);
}

input,
button {
  font-family: var(--font-mono), mono;
  border: none;
  background: transparent;
  color: var(--fg);
  padding: 0;
  font-size: 1em;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
}

header {
  width: 100%;
  text-align: center;
  margin-bottom: var(--margin-04);
}

header h1 {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

header svg {
  height: 22px;
}

header .wrapper.-box {
}

header .wrapper.-box:hover .arrows {
  color: var(--hl);
}

header .wrapper.-box:hover span.-active {
  color: var(--hl);
}

header svg,
header span:not(:last-child) {
  margin-right: var(--margin-04);
}

header button {
  width: 100%;
  display: block;
  padding: var(--margin-02);
}

header span {
  color: var(--fg);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

header span.-active {
  color: var(--hl);
}

header .arrows {
  display: block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 1.5em;
}

header .arrows.-right {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

footer {
  width: 100%;
  text-align: center;
  margin-top: var(--margin-04);
}

footer .wrapper {
  padding: var(--margin-02);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

footer *:not(:last-child) {
  margin-right: var(--margin-01);
}

footer a {
  display: inline-block;
  color: var(--fg);
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-weight: bolder;
}

footer a:hover {
  color: var(--hl);
}

footer button {
  flex-flow: row;
  align-items: center;
  justify-content: center;
  display: flex;
}

footer button.-active {
  color: var(--hl);
}

.input-wrapper {
  padding: var(--margin-half);
  border: 1px solid var(--fg);
}

.info {
  background: var(--hl-alt);
}

.rich-text a {
  color: var(--fg);
  border-bottom: 1px solid var(--fg);
  text-decoration: none;
}

.rich-text h3 {
  margin-bottom: var(--margin-02);
}

.rich-text hr {
  margin-top: var(--margin-04);
  margin-bottom: var(--margin-04);
}

.rich-text p {
  margin: 0;
}

.rich-text p:not(:last-child) {
  margin-bottom: var(--margin-02);
}

.options {
  position: absolute;
  top: 0;
  right: 0;

  height: 100vh;
  width: 100vw;
  padding-top: var(--margin-04);
  padding-bottom: var(--margin-04);

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  overflow: auto;
}

.options h2 {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.options h2 span {
  margin-left: var(--margin-01);
}

.options-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bg-a);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.options .wrapper {
  /* padding-top: var(--margin-02); */
  /* padding-bottom: var(--margin-02); */
}

.options .wrapper::after {
  height: var(--margin-01);
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARYAAAAKAQMAAABL4ONYAAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMA32D/An0AAAC0SURBVBjTXcYxboRADEDR75ELK5VBOYBBHMSsiLR7Ky+aghLlxGln86qHtNYoFsPOpqqCDufhOM8I9xljzrgzI7aPO1vBZbtwn15i4n42ieGLLsDKGwulxcUhDU/7uO6Ouc7Zl+3S2Dxse9s+/PFyS6onCgQiYoX9ewKORE3+bKta0HbWGH/rYeyTN+9JVca5hi7Wh5cIhYITX7PZhEGQ8/DvzT3wY+0vqoKucf3YrzBcSLE/lKQuSiByDCcAAAAASUVORK5CYII=);
  pointer-events: none;
  content: ' ';
  display: block;
}

.options .wrapper::before {
  height: var(--margin-01);
  right: 0;
  left: 0;
  top: 0;
  position: absolute;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARYAAAAKAQMAAABL4ONYAAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMA32D/An0AAAC0SURBVBjTXcYxboRADEDR75ELK5VBOYBBHMSsiLR7Ky+aghLlxGln86qHtNYoFsPOpqqCDufhOM8I9xljzrgzI7aPO1vBZbtwn15i4n42ieGLLsDKGwulxcUhDU/7uO6Ouc7Zl+3S2Dxse9s+/PFyS6onCgQiYoX9ewKORE3+bKta0HbWGH/rYeyTN+9JVca5hi7Wh5cIhYITX7PZhEGQ8/DvzT3wY+0vqoKucf3YrzBcSLE/lKQuSiByDCcAAAAASUVORK5CYII=);
  pointer-events: none;
  content: ' ';
  display: block;
}

.input-w-clipboard {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2em;
  width: 100%;
  cursor: pointer;
}

.input-w-clipboard:hover svg {
  color: var(--hl);
}

.input-w-clipboard:not(:only-child):not(:last-child) {
  margin-bottom: var(--margin-02);
}

.input-w-clipboard label {
  text-align: left;
  width: auto;
  flex: 1 1 auto;
}

.rc-input-number-input-wrap {
  flex: 2 1;
  margin-left: var(--margin-02);
}

.input-w-clipboard input {
  width: 100%;
  text-align: right;
}

.input-w-clipboard span {
  display: block;
  width: auto;
  height: auto;
}

.input-w-clipboard span,
.input-w-clipboard input:-moz-read-only {
}

.input-w-clipboard span,
.input-w-clipboard input:read-only {
}

.input-w-clipboard svg {
  height: 18px;
  margin-left: var(--margin-02);
}


