.input-w-clipboard {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2em;
  width: 100%;
  cursor: pointer;
}

.input-w-clipboard:hover svg {
  color: var(--hl);
}

.input-w-clipboard:not(:only-child):not(:last-child) {
  margin-bottom: var(--margin-02);
}

.input-w-clipboard label {
  text-align: left;
  width: auto;
  flex: 1 1 auto;
}

.rc-input-number-input-wrap {
  flex: 2;
  margin-left: var(--margin-02);
}

.input-w-clipboard input {
  width: 100%;
  text-align: right;
}

.input-w-clipboard span {
  display: block;
  width: auto;
  height: auto;
}

.input-w-clipboard span,
.input-w-clipboard input:read-only {
}

.input-w-clipboard svg {
  height: 18px;
  margin-left: var(--margin-02);
}
